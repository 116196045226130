import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

const SelectionsPage = () => {
  const data = useStaticQuery(query)
  const selections = data.allStrapiWipSelection.edges

  return (
    <div>
      <h1>Selections</h1>
      {selections.length &&
        <ul>
          {selections.map((selection, index) => (
            <li key={index}>{selection.node.title}</li>
          ))}
        </ul>
      }
    </div>
  )
}

const query = graphql`
  query {
    allStrapiWipSelection {
      edges {
        node {
          strapiId
          slug
          title
        }
      }
    }
  }
`

export default SelectionsPage
